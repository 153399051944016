import Index from "./Index";
import ErrorPage from "../components/Page404/ErrorPage";
import Terms from "../pages/Terms/Terms";
import PrivacyPolicy from "../pages/PrivacyPolicy/PrivacyPolicy";
import Layout from "../layout/Layout";
import WhoWeAre from "../pages/WhoWeAre/WhoWeAre";

export const privateRoute = [
  {
    path: "/",
    // element: <Index/>,
    element: Index,
  },
  {
    path: "/",
    element: Layout,
    children: [
      {
        path: "/who-we-are",
        element: WhoWeAre,
      },
      {
        path: "/term-conditions",
        element: Terms,
      },
      {
        path: "/privacy-policy",
        element: PrivacyPolicy,
      },
    ],
  },
  {
    path: "*",
    element: <ErrorPage/>,
  },
];
