import React, { useLayoutEffect } from 'react'
import Header from '../components/header/Header'
import { Outlet, useLocation } from 'react-router-dom'
import PagesHeader from '../components/header/PagesHeader'
import Footer from '../components/footer/Footer'
import PagesFooter from '../components/footer/PagesFooter'
import { useTranslation } from 'react-i18next'


const Layout = () => {
  const location = useLocation()
  const {i18n: {language}} = useTranslation()
  
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
}, [location]);
  return (
    <>
      <PagesHeader />
      <Outlet />
      <PagesFooter />
    </>
  )
}

export default Layout
