import React, { useState } from "react";
import { ContactWrapper } from "./styled";
import { Col, Container, Form, Row } from "react-bootstrap";
import {
	Box,
	HeadingStyled,
	LabelStyled,
	SubHeadingStyled,
	TextStyled,
} from "../../Styled/Elements";
import { useTranslation } from "react-i18next";
import {
	ButtonCustom,
	ContactButton,
	InputStyled,
	TextAreaStyled,
} from "../../Styled/CustomElements";
import { Location, Message, Phone2 } from "../../Styled/AllImages";
import { socialIcon } from "../../data/Data";
import { useForm } from "react-hook-form";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const Contact = () => {
	const {
		t,
		i18n: { language },
	} = useTranslation();
	const [isLoading, setIsLoading] = useState(false);
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm({
		defaultValues: {
			name: "",
			email: "",
			message: "",
		},
	});

	const getApi = async (data) => {
		try {
			setIsLoading(true);
			const response = await axios.post(
				"https://devapi.cloudarchive.app/api/v1/contact/us",
				data
			);
			
			if (response.status === 200) {
				reset({
					name: "",
					email: "",
					message: "",
				});
				toast.success(t("submission"), {
					position: "bottom-center",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "colored",
				});
			} else {
				toast.error(t("submission_fail"), {
					position: "bottom-center",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "colored",
				});
			}
		} catch (error) {
			console.error("Error submitting the form:", error);
			toast.error(t("submission_fail"), {
				position: "bottom-center",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "colored",
			});
		} finally {
			setIsLoading(false)
		}
	};
	const onSubmit = async (data) => {
		console.log(data);
		getApi(data);
	};
	return (
		<ContactWrapper id="contact">
			<Container>
				<Box className="contact-box">
					<Row>
						<Col lg={6}>
							<Box>
								<HeadingStyled
									color="var(--white)"
									size="36px"
									className="main-heading"
								>
									{t("contact_for_assistance")}
								</HeadingStyled>
								<TextStyled size="16px" color="var(--white)">
									{t('connect_with_us_for_assistance')}
								</TextStyled>
								<Box>
									<ButtonCustom
										label="+96632432452"
										icon={Phone2}
										bg="none"
										color="var(--white)"
										className="p-0"
									/>
									<ButtonCustom
										label="support@cloudarchive.com"
										icon={Message}
										bg="none"
										color="var(--white)"
										className="p-0"
									/>
									<ButtonCustom
										label="Al Ta'aown Dist.,, P.O.Box : 513"
										icon={Location}
										bg="none"
										color="var(--white)"
										className="p-0"
									/>
								</Box>
								<Box>
									<SubHeadingStyled
										className="main-heading mt-4"
										size="24px"
										color="var(--white)"
									>
										{t('follow_us_on')}
									</SubHeadingStyled>
									<Row className="mt-3">
										{socialIcon.map((list, key) => (
											<Col className="contact-links" key={key}>
												<a
													href={list.link}
													target="_blank"
													rel="noopener noreferrer"
												>
													<div className="social-icon d-flex justify-content-center align-items-center">
														{list.img}
													</div>
												</a>
											</Col>
										))}
									</Row>
								</Box>
							</Box>
						</Col>
						<Col lg={6}>
							<form onSubmit={handleSubmit(onSubmit)}>
								<HeadingStyled
									color="var(--white)"
									size="36px"
									className="main-heading"
								>
									{t("contact_form")}
								</HeadingStyled>
								<Box className="mt-3">
									<Box className="field-box">
										<LabelStyled color="var(--white)" size="16px" weight="700">
											{t("name")}
										</LabelStyled>
										<InputStyled
											name="name"
											placeholder={t("namePlaceholder")}
											{...register("name", { required: true })}
              								aria-invalid={errors.name ? "true" : "false"}
										/>
										{errors.name?.type === "required" && (
											<p className="error-message" role="alert">
												{t("name_error")}
											</p>
										)}
									</Box>
									<Box className="field-box">
										<LabelStyled color="var(--white)" size="16px" weight="700">
											{t("email")}
										</LabelStyled>
										<InputStyled
											name="email"
											type="email"
											placeholder={t("emailPlaceholder")}
											{...register("email", { required: true })}
											aria-invalid={errors.email ? "true" : "false"}
										/>
										{errors.email && (
											<p className="error-message" role="alert">
												{t("email_error")}
											</p>
										)}
									</Box>
									<Box className="field-box">
										<LabelStyled color="var(--white)" size="16px" weight="700">
											{t("yourMessage")}
										</LabelStyled>
										<TextAreaStyled
											name="message"
											placeholder={t("messagePlaceholder")}
											{...register("message", { required: true })}
              								aria-invalid={errors.message ? "true" : "false"}
										/>
										{errors.message && (
										<p className="error-message" role="alert">
											{t("message_error")}
										</p>
										)}
									</Box>
									<Box className="d-flex justify-content-end">
										<ContactButton
											label={t("Send")}
											bg="var(--white)"
											color="var(--themeColor2)"
											type="submit"
										/>
									</Box>
								</Box>
							</form>
						</Col>
					</Row>
				</Box>
			</Container>
		</ContactWrapper>
	);
};

export default Contact;
