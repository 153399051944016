import styled from "styled-components";

export const WhoWeAreWrapper = styled.section`
    
    .row-content-img{
        align-items: center;
        padding-bottom: 80px;
        &.row-order-1 > :first-child{
          padding-right: 50px;
        }
        &.row-order-1 > :last-child{
          padding-left: 50px;
        }
        &.row-order-2 > :first-child{
            order: 2;
            padding-left: 50px;
        }
        &.row-order-2 > :last-child{
            order: 1;
            padding-right: 50px;
        }
        body.rtl &{
            &.row-order-1 > :first-child{
                padding-left: 50px;
                padding-right: calc(var(--bs-gutter-x) * .5);
            }
            &.row-order-1 > :last-child{
                padding-right: 50px;
                padding-left: calc(var(--bs-gutter-x) * .5);
            }
            &.row-order-2 > :first-child{
                padding-right: 50px;
                padding-left: calc(var(--bs-gutter-x) * .5);
            }
            &.row-order-2 > :last-child{
                padding-left: 50px;
                padding-right: calc(var(--bs-gutter-x) * .5);
            }
        }
        &:last-child{
            padding-bottom: 0;
            
        }
    }
    
`