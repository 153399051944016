import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, HeadingStyled, HeadingStyled2, HeadingStyledD, SpanStyled, SubHeadingStyled } from '../../components/Styled/Elements'
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import styled from 'styled-components'
import { ContainerPrivacyTerm } from '../Styled'


const PrivacyPolicy = () => {
	const { t, i18n: { language } } = useTranslation()
	const [htmlContent, setHtmlContent] = useState("")

	return (
		<ContainerPrivacyTerm className="container common-container privacy-terms-container">
			<Box>
				<HeadingStyled
					color="var(--themeColor)"
					weight="700"
					size="36px"
					className="main-heading"
				>
					{t("privacy_policy")}
				</HeadingStyled>
			</Box>
			{/* <Box>
				<HeadingStyled2 className='prTcTitle'>
					{t("title")}
				</HeadingStyled2>
			</Box> */}
			<Box className="mt-3 privacyContainer">
				<Box>
					<HeadingStyledD className='privacySubH'>
						{t("PrivacyH1")}
					</HeadingStyledD>
				</Box>
				<SpanStyled>
					{t("PrivacyT1")}
				</SpanStyled>
				<Box>
					<HeadingStyledD className='privacySubH'>
						{t("PrivacyH2")}
					</HeadingStyledD>
				</Box>
				<SpanStyled>
					{t("PrivacyT2")}
				</SpanStyled>
				<Box>
					<HeadingStyledD className='privacySubH'>
						{t("PrivacyH3")}
					</HeadingStyledD>
				</Box>
				
				<SpanStyled>
					{t("PrivacyT3")}
				</SpanStyled>
				<Box>
					<HeadingStyledD className='privacySubH'>
						{t("PrivacyH4")}
					</HeadingStyledD>
				</Box>
				<SpanStyled>
					{t("PrivacyT4")}
				</SpanStyled>
				<Box>
					<HeadingStyledD className='privacySubH'>
						{t("PrivacyH5")}
					</HeadingStyledD>
				</Box>
				<SpanStyled>
					{t("PrivacyT5")}
				</SpanStyled>
				<Box>
					<HeadingStyledD className='privacySubH'>
						{t("PrivacyH6")}
					</HeadingStyledD>
				</Box>
				<SpanStyled>
					{t("PrivacyT6")}
				</SpanStyled>
				
				{/* <Box>
					<ul className='prTcList'>
						<li>{t("")}</li>
					</ul>
				</Box> */}
				
				<Box>
					<HeadingStyledD>
						{t("PrivacyH7")}
					</HeadingStyledD>
				</Box>
				
				<SpanStyled>
					{t("PrivacyT7")}
				</SpanStyled>
				<Box>
					<HeadingStyledD className='privacySubH'>
						{t("PrivacyH8")}
					</HeadingStyledD>
				</Box>
				
				<SpanStyled>
					{t("PrivacyT8")}
				</SpanStyled>
				<Box>
					<HeadingStyledD className='privacySubH'>
						{t("PrivacyH9")}
					</HeadingStyledD>
				</Box>
				
				<SpanStyled>
					{t("PrivacyT9")}
				</SpanStyled>
				<Box>
					<HeadingStyledD className='privacySubH'>
						{t("PrivacyH10")}
					</HeadingStyledD>
				</Box>
				
				<SpanStyled>
					{t("PrivacyT10")}
					{/* <span className='engWd'></span> */}
				</SpanStyled>
				<Box>
					<HeadingStyledD className='privacySubH'>
						{t("PrivacyH11")}
					</HeadingStyledD>
				</Box>
				<SpanStyled>
					{t("PrivacyT11")}
				</SpanStyled>
			</Box>
		</ContainerPrivacyTerm>
	)
}

export default PrivacyPolicy

