import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, HeadingStyled, HeadingStyledD, Span, SpanStyled, SubHeadingStyled } from '../../components/Styled/Elements'
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import { ContainerPrivacyTerm } from '../Styled'

const Terms = () => {
	const { t, i18n: { language } } = useTranslation()

	return (
		<ContainerPrivacyTerm className="container common-container privacy-terms-container">
			<Box>
				<HeadingStyled
					color="var(--themeColor)"
					weight="700"
					size="36px"
					className="main-heading"
				>
					{t("terms_condition")}
				</HeadingStyled>
			</Box>
			{/* <Box>
				<HeadingStyled2 className='prTcTitle'>
					{t("title")}
				</HeadingStyled2>
			</Box> */}
			<Box className="mt-3 privacyContainer">
				<SpanStyled>
					{t("TermsT1")}
				</SpanStyled>
				<Box>
					<HeadingStyledD className='privacySubH'>
						{t("TermsH1")}
					</HeadingStyledD>
				</Box>
				<Box>
					<Span weight="600">{t("TermsT2H")}</Span>&nbsp;
					<SpanStyled>
						{t("TermsT2")}
					</SpanStyled>	
				</Box>
				<br />
				<Box>
					<Span weight="600">{t("TermsT3H")}</Span>&nbsp;
					<SpanStyled>
						{t("TermsT3")}
					</SpanStyled>	
				</Box>
				<br />
				<SpanStyled>
					{t("TermsT4")}
				</SpanStyled>
				<br />
				<SpanStyled>
					{t("TermsT5")}
				</SpanStyled>
				<br />
				<SpanStyled>
					{t("TermsT6")}
				</SpanStyled>
				<br />
				<Box>
					<HeadingStyledD className='privacySubH'>
						{t("TermsH7")}
					</HeadingStyledD>
				</Box>
				<SpanStyled>
					{t("TermsT7")}
				</SpanStyled>
				<br />
				<SpanStyled>
					{t("TermsT8")}
				</SpanStyled>
				<br />
				<SpanStyled>
					{t("TermsT9")}
				</SpanStyled>
				<br />
				<SpanStyled>
					{t("TermsT9")}
				</SpanStyled>
				<br />
				<SpanStyled>
					{t("TermsT10")}
				</SpanStyled>
				<br />
				<SpanStyled>
					{t("TermsT11")}
				</SpanStyled>
				<br />
				<SpanStyled>
					{t("TermsT12")}
				</SpanStyled>
				<br />
				<SpanStyled>
					{t("TermsT13")}
				</SpanStyled>
				<br />
				<SpanStyled>
					{t("TermsT14")}
				</SpanStyled>
				<br />
				<SpanStyled>
					{t("TermsT15")}
				</SpanStyled>
				<br />
				<SpanStyled>
					{t("TermsT16")}
				</SpanStyled>
				<br />
				<SpanStyled>
					{t("TermsT17")}
				</SpanStyled>
				<br />
				<SpanStyled>
					{t("TermsT18")}
				</SpanStyled>
				<Box>
					<HeadingStyledD className='privacySubH'>
						{t("TermsH19")}
					</HeadingStyledD>
				</Box>
				<SpanStyled>
					{t("TermsT19")}
				</SpanStyled>
				<br />
				<SpanStyled>
					{t("TermsT20")}
				</SpanStyled>

			</Box>
		</ContainerPrivacyTerm>
	)
}

export default Terms