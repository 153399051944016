import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, HeadingStyled, SubHeadingStyled, TextStyled } from '../../components/Styled/Elements'
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import { Screen1, Screen2, Screen3, Screen4 } from '../../components/Styled/AllImages'
import { WhoWeAreWrapper } from './styled'

const WhoWeAre = () => {
	const { t, i18n: { language } } = useTranslation()

	return (
		<WhoWeAreWrapper className="common-container container">
			<Box>
				<HeadingStyled
					color="var(--themeColor)"
					weight="700"
					size="36px"
					className="main-heading"
				>
					{t("WhoWeAre")}
				</HeadingStyled>
			</Box>
			<Box className="mt-4">
				<Row className=''>
					<Col md={12}>
						<TextStyled
							color="var(--text)"
							weight="400"
							size="16px"
							className="mt-3 mb-4"
						>
							{t("AbutTxt1")}
							<br />
							{t("AbutTxt2")}
						</TextStyled>
					</Col>
					
				</Row>
				<Row className=''>
					<Col md={12}>
						<TextStyled
							color="var(--text)"
							weight="400"
							size="16px"
							className="mt-3 mb-4"
						>
							{t("AbutTxt3")}
							<br />
							{t("AbutTxt4")}
						</TextStyled>
					</Col>
					
				</Row>
				<Row className=''>
					<Col md={12}>
						<TextStyled
							color="var(--text)"
							weight="400"
							size="16px"
							className="mt-3 mb-4"
						>
							{t("AbutTxt5")}
							<br />
							{t("AbutTxt6")}
						</TextStyled>
					</Col>
					
				</Row>
				<Row className=''>
					<Col md={12}>
						<TextStyled
							color="var(--text)"
							weight="400"
							size="16px"
							className="mt-3 mb-4"
						>
							{t("AbutTxt7")}
							<br />
							{t("AbutTxt8")}
						</TextStyled>
					</Col>
					
				</Row>
			</Box>
			{/* <Box className="mt-5">
				<Row className='row-content-img row-order-1'>
					<Col md={6}>
						<TextStyled
							color="var(--text)"
							weight="400"
							size="16px"
							className="mt-3 mb-4"
						>
							{t("AbutTxt1")}
							<br />
							{t("AbutTxt2")}
						</TextStyled>
					</Col>
					<Col md={6}>
						<img src={Screen1} alt='Screen' />
					</Col>
				</Row>
				<Row className='row-content-img row-order-2'>
					<Col md={6}>
						<TextStyled
							color="var(--text)"
							weight="400"
							size="16px"
							className="mt-3 mb-4"
						>
							{t("AbutTxt3")}
							<br />
							{t("AbutTxt4")}
						</TextStyled>
					</Col>
					<Col md={6}>
						<img src={Screen2} alt='Screen' />
					</Col>
				</Row>
				<Row className='row-content-img row-order-1'>
					<Col md={6}>
						<TextStyled
							color="var(--text)"
							weight="400"
							size="16px"
							className="mt-3 mb-4"
						>
							{t("AbutTxt5")}
							<br />
							{t("AbutTxt6")}
						</TextStyled>
					</Col>
					<Col md={6}>
						<img src={Screen3} alt='Screen' />
					</Col>
				</Row>
				<Row className='row-content-img row-order-2'>
					<Col md={6}>
						<TextStyled
							color="var(--text)"
							weight="400"
							size="16px"
							className="mt-3 mb-4"
						>
							{t("AbutTxt7")}
							<br />
							{t("AbutTxt8")}
						</TextStyled>
					</Col>
					<Col md={6}>
						<img src={Screen4} alt='Screen' />
					</Col>
				</Row>
			</Box> */}
		</WhoWeAreWrapper>
	)
}

export default WhoWeAre