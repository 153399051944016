import styled from "styled-components";

export const ContactWrapper = styled.section`
    .contact-box{
        padding: 2rem 3rem;
        background: var(--themeGradient);
        border-radius: 16px;
    }
    .contact-links.col{
        flex: 0!important;
    }
    .social-icon{
    background-color: #ffffff33;
    border-radius: 9px;
    width: 42px;
    height: 42px;
  }
  .error-message {
    font-family: var(--regular);
    padding: 1px 8px;
    border-radius: 5px;
    color: white;
    background-color: red;
    margin-top: 4px;
    font-size: 14px;
    body.rtl &{
        font-family: var(--arRegular);
        font-size: 13px;
    }
  }
  .loading-btn {
    position: absolute;
    left: 0;
    right: 0;
    width: 50%;
    height: 20%;
    z-index: 10;
    background: #0b0b0b6b;
    text-align: center;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
    font-family: var(--regular);
  }

`