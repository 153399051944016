import styled from "styled-components";

export const ContainerPrivacyTerm = styled.div`
	.privacyContainer{
		& > span{
			display: inline-block;
			width: 100%;
			margin-bottom: 20px;
		}
	}
`
