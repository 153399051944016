import { Link, scroller } from "react-scroll";
import { Box, LazyImage, Span } from "../Styled/Elements";
import { HeaderContainer } from "./Styled";
import { NavDataConfig, PageNavConfig } from "../data/Data";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BsJustify, BsX } from "react-icons/bs";
import { Container, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { LogoEn, LogoAr } from "../Styled/AllImages";
import { ContactButton } from "../Styled/CustomElements";
import LangChangeButton from "../../Languages/LangChangeButton";
import { toast } from "react-toastify";

const PagesHeader = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const {
		t,
		i18n: { language },
	} = useTranslation();
	const [isSticky, setIsSticky] = useState(false);
	const [toggle, setToggle] = useState(false);

	

	const handleSignupButton = () => {
		// toast.error(t("select_plan_to_continue"))
		scroller.scrollTo('pricing', {
			duration: 500,
			smooth: true,
		})
	}

	const toMainPage = (label, isLink, to) => {
		handleToggle();
		if (isLink) {
		  navigate(to);
		} else navigate(`/?section=${label}`);
	  };
	  const handleToggle = () => {
		setToggle(!toggle);
		const element = document.querySelector(".nav-bar-list");
		element.classList.toggle("showToggle");
	  };
	
	  useEffect(() => {
		const handleScroll = () => {
		  if (window.scrollY > 1) {
			setIsSticky(true);
		  } else {
			setIsSticky(false);
		  }
		};
		window.addEventListener("scroll", handleScroll);
		return () => {
		  window.removeEventListener("scroll", handleScroll);
		};
	  }, []);
	  console.log(location);
	return (
		<>
			<HeaderContainer
				className={`${isSticky ? "sticky" : ""} container-fluid`}
			>
				<Container>
					<Box className="nav-bar">
						<Box className="logo" onClick={() => navigate("/")}>
							{language === "ar" ? (
								<LazyImage src={LogoAr} />
							) : (
								<LazyImage src={LogoEn} />
							)}
						</Box>
						<Box className="nav-bar-list">
						{PageNavConfig.map((item, key) => (
							<Box key={key}>
								<Span
								// activeClass="active"
								className={location.pathname === item.to && "active" }
								onClick={() => toMainPage(item.label, item.link, item.to)}
								>
								{t(item.label)}
								</Span>
							</Box>
							))}
						</Box>
						<Box className="whtLngWrapper d-flex align-items-center justify-content-center">
							<ContactButton label={t('sign_in')} bg="var(--white)" color="var(--text)" clName={"signin-btn"} onClick={() => window.open("https://devapp.cloudarchive.app/login", "_blank")} />
							<ContactButton label={t('sign_up_for_free')} color="var(--white)" bg="var(--themeColor)" clName={"signup-btn"} onClick={handleSignupButton} />
							<LangChangeButton />
							<span className="toggleBtn" onClick={handleToggle}>
								{toggle ? <BsX size={30} /> : <BsJustify size={30} />}
							</span>
						</Box>
					</Box>
				</Container>
			</HeaderContainer>
		</>
	);
};

export default PagesHeader;
