import React, { useEffect } from "react";
import { BannerWrapper } from "./Styled";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  Box,
  HeadingStyled,
  LazyImage,
  TextStyled,
} from "../../Styled/Elements";
import { ContactButton } from "../../Styled/CustomElements";
import { BannerBg, BannerBgAr } from "../../Styled/AllImages";
import { scroller } from "react-scroll";
import { toast } from "react-toastify";

const Banner = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const renderStyledText = (text) => {
    return text.replace(
      /<color>(.*?)<\/color>/g,
      '<span style="color: var(--themeColor2);">$1</span>'
    );
  };

  const secureText = renderStyledText(t("secure_your_data_with_cloud_archive"));

  const handleSignupButton = () => {
    // toast.error(t("select_plan_to_continue"))
    scroller.scrollTo('pricing', {
      duration: 500,
      smooth: true,
    })
  }

  // useEffect(() => {
  //   window.addEventListener("scroll", () => {
  //     let scrollY = window.scrollY;
  //     document.documentElement.style.setProperty("--scrollTop", `${scrollY}px`);
  //   });
  // }, []);

  return (
    <BannerWrapper className="banner" id="home" lang={language}>
      <Row>
        <Col className="position-relative">
          <Box className="header-main">
            <Container className="banner-container">
              {language == "ar" ? (
                <img src={BannerBgAr} className="banner-bg" alt="" />
              ) : (
                <img src={BannerBg} className="banner-bg" alt="" />
              )}
              <Box className="bannerContentWrapper">
                <Box className="banner-content col-md-12 col-lg-6">
                  <HeadingStyled
                    className="main-heading caption-sub-heading"
                    color="var(--text)"
                    size="36px"
                    weight="900"
                    dangerouslySetInnerHTML={{ __html: secureText }}
                  />
                  <TextStyled
                    className=""
                    color="var(--text)"
                    size="20px"
                    lh="1"
                  >
                    {t("access_organize_protect_docs")}
                  </TextStyled>
                  <ContactButton clName="mt-4" label={t("get_started_its_free")} onClick={handleSignupButton} />
                  <TextStyled size="16px" color="var(--iconColor)" className="mt-3">{t("free_forever_no_credit")}</TextStyled>
                </Box>
              </Box>
            </Container>
          </Box>
        </Col>
      </Row>
    </BannerWrapper>
  );
};

export default Banner;
