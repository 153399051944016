import React from "react";
import { FooterWrapper } from "./Styled";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
	Box,
	HeadingStyled,
	LazyImage,
	SpanStyled,
	TextStyled,
} from "../Styled/Elements";
import { LogoEn, LogoAr } from "../Styled/AllImages";
import { footerNavs, socialIcon } from "../data/Data";
import { useNavigate, Navigate, Link as RouterLink } from "react-router-dom";
import { Link } from "react-scroll";
const PagesFooter = () => {
	const {
		t,
		i18n: { language },
	} = useTranslation();
	const navigate = useNavigate();
	const date_year = new Date();
	const FowlUrl = () => {
		const whatsappURL = "https://fowl.sa/home";
		window.open(whatsappURL, "_blank");
	};
	const toMainPage = (label, isLink, to) => {
		if (isLink) {
		  navigate(to);
		} else navigate(`/?section=${label}`);
	  };
	const FowlWeb = () => {
		window.open("https://websites.fowl.sa/", "_blank");
	};
	return (
		<FooterWrapper className="container-fluid footerWrapper" lang={language}>
			<Box className="footerCrWrapper">
				<Container className="footerContain2 pagesFooter">
					<Row className="footer-row align-items-center justify-content-center justify-content-sm-between">
						<Box className="footerLogo mt-2">
							{language === "ar" ? (
								<LazyImage src={LogoAr} />
							) : (
								<LazyImage src={LogoEn} />
							)}
						</Box>
						<Box className="text-center copyright mt-2">
							<Box className="footerLink">
								{footerNavs.map((item, key) => (
									<Box key={key}>
										<SpanStyled
										onClick={() => toMainPage(item.label, item.link, item.to)}
										className="footer-op footer-op-allRights"
										size="14px"
										color="var(--white)"
										style={{cursor: 'pointer'}}
										>
										{t(item.label)}
										</SpanStyled>
										{/* {item.link ? (
										<RouterLink to={item.to} activeClass="active">
											{t(item.label)}
										</RouterLink>
										) : (
										<Link
											to={item.to}
											spy={true}
											smooth={true}
											offset={-60}
											duration={500}
											activeClass="active"
										>
											{t(item.label)}{" "}
										</Link>
										)} */}
									</Box>
									))}
								{/* {footerNavs.map((item, key) => (
									<Box key={key}>
										{item.link ? (
										<RouterLink to={item.to} activeClass="active">
											{t(item.label)}
										</RouterLink>
										) : (
										<Link
											to={item.to}
											spy={true}
											smooth={true}
											offset={-60}
											duration={500}
											// activeClass="active"
										>
											{t(item.label)}{" "}
										</Link>
										)}
									</Box>
								))} */}
							</Box>
							<Box>
								<SpanStyled
									className="footer-op"
									size="14px"
									color="var(--text)"
									transform="capitalize"
								>
									&copy; {date_year.getFullYear()}
									<SpanStyled
										className="footer-op footer-op-company"
										size="14px"
										color="var(--themeColor)"
										weight="600"
									>
										{" "}
										{t("cloud_archive")}.
									</SpanStyled>
								</SpanStyled>
								<SpanStyled
									className="footer-op footer-op-allRights"
									size="14px"
									color="var(--text)"
									transform="capitalize"
								>
									{" "}
									{t("AllRightReserved2")}.
								</SpanStyled>
							</Box>
						</Box>
						<Row className="d-flex mt-2">
							{socialIcon.map((list, key) => (
								<Col className="contact-links" key={key}>
									<a href={list.link} target="_blank" rel="noopener noreferrer">
										<div className="social-icon d-flex justify-content-center align-items-center">{list.img}</div>
									</a>
								</Col>
							))}
						</Row>
					</Row>
				</Container>
			</Box>
		</FooterWrapper>
	);
};

export default PagesFooter;
