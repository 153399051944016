import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input'
import flags from "react-phone-number-input/flags";
import { Box, LabelStyled } from "../Elements";
import ValidationErrorText from "./ValidationErrorText";

const PhoneInputField = ({
  name,
  control,
  label,
  disabled,
  className,
  staric,
  classNameLabel,
  errorDisplay,
  errorMessage = "",
  validationRules,
  register,
  ...rest
}) => {
  const { t } = useTranslation();

  const defaultValidationRules = {
    validate: {
      validNumber: (value) => {
        if (!value) return true;

        try {
          const phoneNumber = parsePhoneNumber(value);

          // Check if the number is valid for any country
          if (!isValidPhoneNumber(value)) {
            return "invalidPhoneNumber";
          }

          if (!phoneNumber.isValid()) {
            return "invalidPhoneNumber";
          }

          // Get the national number without country code
          const nationalNumber = phoneNumber.nationalNumber;

          if (nationalNumber.length < 4) {
            return "phoneNumberTooShort";
          }
          if (nationalNumber.length > 15) {
            return "phoneNumberTooLong";
          }

          return true;
        } catch (error) {
          return "invalidPhoneNumber";
        }
      },
    },
  };

  const combinedValidationRules = {
    ...defaultValidationRules,
    ...validationRules,
  };

  return (
    <PhoneInputWrapper className={className} {...rest}>
      <div className="formEleContainer">
        {label && (
          <LabelStyled color="var(--text)" size="16px" weight="700" className={classNameLabel}>
          {t(label)}
        </LabelStyled>
        )}
        <Box className="position-relative w-100">
          <Controller
            name={name}
            control={control}
            rules={combinedValidationRules}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <PhoneInput
                  international
                  defaultCountry="SA"
                  value={value}
                  onChange={onChange}
                  disabled={disabled}
                  flags={flags}
                  className="phone-input px-2 px-sm-3"
                />
                {error && (
                  <>
                    {error?.type === "required" && (
                      <ValidationErrorText
                        text={
                          t(error?.message) ||
                          `${t(label || name)} ${t("isRequired")}.`
                        }
                        extraClass={errorDisplay}
                      />
                    )}
                    {error?.type === "minLength" && (
                      <ValidationErrorText
                        text={t(error?.message)}
                        extraClass={errorDisplay}
                      />
                    )}

                    {error?.type === "maxLength" && (
                      <ValidationErrorText
                        text={t(error?.message)}
                        extraClass={errorDisplay}
                      />
                    )}

                    {error?.type === "pattern" && (
                      <ValidationErrorText
                        text={t(error?.message || errorMessage)}
                        extraClass={errorDisplay}
                      />
                    )}
                    {error?.type === "validNumber" && (
                      <ValidationErrorText
                        text={t(error?.message || errorMessage)}
                        extraClass={errorDisplay}
                      />
                    )}
                  </>
                )}
              </>
            )}
          />
        </Box>
      </div>
    </PhoneInputWrapper>
  );
};

export default PhoneInputField;

export const PhoneInputWrapper = styled.div`
  width: 100%;
  padding: 9px 25px 9px 25px;
  border-bottom: 1px solid var(--borderLight);
  width: 100%;
  .formEleContainer {
    display: flex;
    padding: 7px 0px 7px 0px;
    margin: 7px 0 7px 0;
    align-items: center;
    & > label {
      min-width: 160px;
      body.rtl & {
        font-family: var(--arRegular);
      }

      &.w-auto {
        min-width: auto;
      }
    }
  }
  &.combined {
    border-bottom: none;
    padding: 8px 25px 8px 25px;
    .formEleContainer {
      display: flex;
      padding: 0;
      margin: 0;
    }
  }
  &.flex-wrap {
    .formEleContainer {
      flex-wrap: wrap;
      label {
        width: 100%;
      }
    }
  }
  .form-floating {
    width: 100%;
  }
  input[type="text"] {
    width: 100%;
  }

  .phone-input {
    height: 43px;
    box-shadow: none;
    outline: none;
    border-radius: 10px;
    border: 1px solid var(--border);
    background-color: ${({ bg }) => (bg ? bg : "var(--bgSecondary)")};
    svg {
      vertical-align: unset !important;
    }
    .PhoneInputInput {
      height: 100%;
      border: none;
      outline: none;
      background: none;
      color: var(--text);
      &::placeholder {
        color: var(--textPlaceholder2);
      }
    }
    body.rtl & {
      &.PhoneInput {
        flex-direction: row-reverse;
      }
      .PhoneInputCountry {
        flex-direction: row-reverse;
      }
    }
    /* .PhoneInputCountrySelectArrow {
      margin: 0;
    } */
  }
  .form-control:focus {
    box-shadow: none;
    outline: none;
    border: 1px solid var(--border);
  }
  @media (max-width: 767px) {
    .formEleContainer {
      flex-wrap: wrap;
      gap: 3px;
      margin: 0;
      padding: 3px 0px 3px 0px;
      label {
        width: 100%;
      }
    }
  }
`;
