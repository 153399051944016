import { Anytime, Effortless, Facebook, Instagram, Realtime, Scalable, Search, Secure, Snapchat, Twitter } from "../Styled/AllImages";
import { Cabinets, Dashboard, FeaturesBg, History, HQBranches, Phone, Requests, Settings, Users } from "../Styled/AllImages";
import { Box, Span, SpanStyled } from "../Styled/Elements";

export const NavDataConfig = [
	{
		to: "home",
		label: "Home",
	},
	{
		to: "features",
		label: "Features",
	},
	{
		to: "pricing",
		label: "Pricing",
	},
	{
		to: "faqs",
		label: "FAQs",
	},
	{
		to: "contact",
		label: "Contact",
	},
];
export const PageNavConfig = [
	{
		to: "/",
		label: "Home",
		link: false
	},
	{
		to: "features",
		label: "Features",
		link: false
	},
	{
		to: "pricing",
		label: "Pricing",
		link: false
	},

	{
		to: "faqs",
		label: "FAQs",
		link: false
	},
	{
		to: "contact",
		label: "Contact",
		link: false
	},
	
];
export const footerNavs = [
	{
		to: "home",
		label: "Home",
		link: false
	},
	{
		to: "features",
		label: "Features",
		link: false
	},
	{
		to: "pricing",
		label: "Pricing",
		link: false
	},

	{
		to: "faqs",
		label: "FAQs",
		link: false
	},
	{
		to: "contact",
		label: "Contact",
		link: false
	},
	{
		to: "who-we-are",
		label: "WhoWeAre",
		link: true
	},
	{
		to: "term-conditions",
		label: "terms_condition",
		link: true
	},
	{
		to: "privacy-policy",
		label: "privacy_policy",
		link: true
	},
];

export const socialIcon = [
	{
		img: <Facebook />,
		link: '#!',
	},

	{
		img: <Twitter />,
		link: '#!',
	},
	{
		img: <Instagram />,
		link: '#!',
	},
	{
		img: <Snapchat />,
		link: '#!',
	},
];

export const features = [
	{
		name: "secure_storage",
		desc: 'data_is_encrypted_stored',
		icon: Secure,
	},
	{
		name: "anytime_anywhere_success",
		desc: 'access_doc_anywhere',
		icon: Anytime,
	},
	{
		name: "scalable_solutions",
		desc: 'easily_scale_your_storage',
		icon: Scalable,
	},
	{
		name: "effortless_organization",
		desc: 'categorize_and_manage_file',
		icon: Effortless,
	},
	{
		name: "realtime_collaboration",
		desc: 'work_together_seamlessly',
		icon: Realtime,
	},
	{
		name: "advance_search",
		desc: 'quickly_find_what_you_need',
		icon: Search,
	},
];
export const modules = [
	{
		name: "dashboard",
		icon: <Dashboard />,
		previewImage: FeaturesBg,
	},
	{
		name: "cabinets",
		icon: <Cabinets />,
		previewImage: FeaturesBg,
	},
	{
		name: "requests",
		icon: <Requests />,
		previewImage: FeaturesBg,
	},
	{
		name: "history",
		icon: <History />,
		previewImage: FeaturesBg,
	},
	{
		name: "users",
		icon: <Users />,
		previewImage: FeaturesBg,
	},
	{
		name: "hq_branches",
		icon: <HQBranches />,
		previewImage: FeaturesBg,
	},
	{
		name: "settings",
		icon: <Settings />,
		previewImage: FeaturesBg,
	},
	{
		name: "support",
		icon: <Phone />,
		previewImage: FeaturesBg,
	},
];
export const pricingPlans = [
	{
		id: 1,
		plan_name: "basic_plan_free",
		desc: "quickest_way_to_try_cloud_archive",
		price: 0,
		btn_text: "get_started",
		color: "var(--themeColor2)",
		stroke: "var(--themeColor2)",
		bg: "--white",
		border: "#eaedf3",
		isBorder: false,
		isCustom: false,
		isBasic: true,
		access_features: [
			"five_archives_allowed",
			"basic_support",
			"access_from_any_device"
		]
	},
	{
		id: 2,
		plan_name: "professional_plan",
		desc: "enhanced_doc_management",
		price: 300,
		btn_text: "get_started",
		color: "var(--white)",
		stroke: "var(--themeColor)",
		bg: "--themeColor",
		border: "var(--themeColor)",
		isBorder: true,
		isCustom: false,
		isBasic: false,
		access_features: [
			"unlimited_archives",
			"priority_support",
			"access_from_any_device"
		]
	},
	{
		id: 3,
		plan_name: "custom_enterprise_plan",
		desc: "custom_solutions_for_comprehensive_doc_management",
		price: 0,
		btn_text: "contact_sales_team",
		color: "var(--themeColor2)",
		stroke: "var(--themeColor2)",
		bg: "--white",
		border: "#eaedf3",
		isBorder: false,
		isCustom: true,
		isBasic: false,
		access_features: [
			"unlimited_archives",
			"tailored_features_integration",
			"dedicated_account_manager",
			"contact_sales_team_for_customized"
		]
	},
]

export const faqData = [
	{
		id: 1,
		question: "Question1",
		render: (t) => (
			<>              
				<SpanStyled>
					{t("Ans1P1")}
				</SpanStyled>
				<Box>
					<ul className='prTcList'>
						<li>
							<Box>
								<Span weight="600">{t("Ans1ListH1")}</Span>&nbsp;
								<SpanStyled>
									{t("Ans1List1")}
								</SpanStyled>	
							</Box>
						</li>
						<li>
							<Box>
								<Span weight="600">{t("Ans1ListH2")}</Span>&nbsp;
								<SpanStyled>
									{t("Ans1List2")}
								</SpanStyled>	
							</Box>
						</li>
						<li>
							<Box>
								<Span weight="600">{t("Ans1ListH3")}</Span>&nbsp;
								<SpanStyled>
									{t("Ans1List3")}
								</SpanStyled>	
							</Box>
						</li>
						<li>
							<Box>
								<Span weight="600">{t("Ans1ListH4")}</Span>&nbsp;
								<SpanStyled>
									{t("Ans1List4")}
								</SpanStyled>	
							</Box>
						</li>
						<li>
							<Box>
								<Span weight="600">{t("Ans1ListH5")}</Span>&nbsp;
								<SpanStyled>
									{t("Ans1List5")}
								</SpanStyled>	
							</Box>
						</li>
						<li>
							<Box>
								<Span weight="600">{t("Ans1ListH6")}</Span>&nbsp;
								<SpanStyled>
									{t("Ans1List6")}
								</SpanStyled>	
							</Box>
						</li>
						
					</ul>
				</Box>
				<SpanStyled>
					{t("Ans1P2")}
				</SpanStyled>
			</>
		),
	},
	/*
		"": "I am an entrepreneur , why should I use Cloud Archive?",
		"": "As a pioneer works, Your time costly And management Your data And your archive Efficiently commander vital For success Your business . Here Comes Cloud Archive, where We offer for you the solution ideal To manage Your archive Effectively And smoothness, with Meet Your needs Growing As a pioneer Business.",
		"": "Here you go Reasons that Make  Cloud Archive Cucumber optimum for you:",
		"": "Central Data:",
		"": "Instead from dispersion Your files via Platforms Different and different devices, You could collection And organizing all Your documents in center one Easy Access To him from any place And in any time.",
		"": "Administration Flexible For permissions:",
		"": "Whether I was It works Alone or Manage team, You could to set from He can Access to Your files, modify it, or Download it, Which Maintains on secrecy Your information And its safety.",
		"": "Reports Clear And record For use:",
		"": "As a pioneer works, You need to knowledge from He looks up on Your documents And when . With Cloud Archive, You could tracking that easily from during Reports Detailed And record transparent For activities.",
		"": "Enhance Productivity:",
		"": "Thanks Our environment Integrated, You could to improve flow the job inside Your team, Which He provides the time And more from efficiency Operations Daily.",
		"": "Solution flexible And acceptable For development:",
		"": "If I was in beginning Your project or Manage a company Fast Growth,  'Cloud Archive' Designer To fit Your needs Current and the future, Which Makes Expansion easy And smoothly.",
		"": "Ease Access And organization:",
		"": "Hatred Look on size Your files or Its type, You will be able to from Organize it And access To her With all ease, Which Gives you time greater To focus on growth Your work.",
		"": "Pioneer Business Successful Needs to tools Smart And effective, And 'Cloud  Archive' presents for you all that And more. We here To be Your partner in Your journey about success, with platform Make administration Your archive an experience Professionalism And easy in that One.",
		*/
	{
		id: 2,
		question: "Question2",
		render: (t) => (
			<>              
				<SpanStyled>
					{t("Ans2P1")}
				</SpanStyled>
				<SpanStyled>
					{t("Ans2P2")}
				</SpanStyled>
				<br />
				<br />
				<Box>
					<Span weight="600">{t("Ans2H3")}</Span>
				</Box>
				<SpanStyled>
					{t("Ans2P3")}
				</SpanStyled>
				<br />
				<br />
				<Box>
					<Span weight="600">{t("Ans2H4")}</Span>
				</Box>
				<SpanStyled>
					{t("Ans2P4")}
				</SpanStyled>
				<br />
				<br />
				<Box>
					<Span weight="600">{t("Ans2H5")}</Span>
				</Box>
				<SpanStyled>
					{t("Ans2P5")}
				</SpanStyled>
				<br />
				<br />
				<Box>
					<Span weight="600">{t("Ans2H6")}</Span>
				</Box>
				<SpanStyled>
					{t("Ans2P6")}
				</SpanStyled>
				<br />
				<br />
				<Box>
					<Span weight="600">{t("Ans2H7")}</Span>
				</Box>
				<SpanStyled>
					{t("Ans2P7")}
				</SpanStyled>
				<br />
				<br />
				<Box>
					<Span weight="600">{t("Ans2H8")}</Span>
				</Box>
				<SpanStyled>
					{t("Ans2P8")}
				</SpanStyled>
				<SpanStyled>
					{t("Ans2P9")}
				</SpanStyled>

					{/* <ul className='prTcList'>
						<li>
							<Box>
								<Span weight="600">{t("Ans1ListH1")}</Span>&nbsp;
								<SpanStyled>
									{t("Ans1List1")}
								</SpanStyled>	
							</Box>
						</li>
						<li>
							<Box>
								<Span weight="600">{t("Ans1ListH2")}</Span>&nbsp;
								<SpanStyled>
									{t("Ans1List2")}
								</SpanStyled>	
							</Box>
						</li>
						<li>
							<Box>
								<Span weight="600">{t("Ans1ListH3")}</Span>&nbsp;
								<SpanStyled>
									{t("Ans1List3")}
								</SpanStyled>	
							</Box>
						</li>
						<li>
							<Box>
								<Span weight="600">{t("Ans1ListH4")}</Span>&nbsp;
								<SpanStyled>
									{t("Ans1List4")}
								</SpanStyled>	
							</Box>
						</li>
						<li>
							<Box>
								<Span weight="600">{t("Ans1ListH5")}</Span>&nbsp;
								<SpanStyled>
									{t("Ans1List5")}
								</SpanStyled>	
							</Box>
						</li>
						<li>
							<Box>
								<Span weight="600">{t("Ans1ListH6")}</Span>&nbsp;
								<SpanStyled>
									{t("Ans1List6")}
								</SpanStyled>	
							</Box>
						</li>
						
					</ul>
				</Box>
				<SpanStyled>
					{t("Ans1P2")}
				</SpanStyled> */}
			</>
		),
	},
	// {
	// 	id: 3,
	// 	question: "can_upgrade_downgrade_plan",
	// 	answer: "basic_plan_offer_10gb_storage"
	// },
	// {
	// 	id: 4,
	// 	question: "what_enterprise_plan_include",
	// 	answer: "basic_plan_offer_10gb_storage"
	// },
	// {
	// 	id: 5,
	// 	question: "is_there_mobile_app_available",
	// 	answer: "basic_plan_offer_10gb_storage"
	// },
]
